import React from 'react';
import './button.css';

function Button(props){

    return (
        <button 
            type={"button"} 
            className={props.styles.join(' ')}
            style={props.inlineStyle}
            disabled={props.disabled}
            onClick={props.onClickHandler}>
                {props.children}
        </button>
    )
}

export default Button;