// app store
export const MENU_TOGGLE = "MENU_TOGGLE";
export const LOGGED_IN = "LOGGED_IN";
export const LOGGED_OUT = "LOGGED_OUT";
export const CHECK_LOGIN = "CHECK_LOGIN";
export const SUBCRIBE_AGENT = "SUBCRIBE_AGENT";
export const PROMO_CODE = "PROMO_CODE";

// auth store
export const MENU_URI = "MENU_URI";
export const CONFIG = "CONFIG";

// ===== child store =====

export const CUSTOMRES = "CUSTOMRES";
export const AGENTS = "AGENTS";
export const CURRENCY = "CURRENCY";
export const CUSTOMRE = "CUSTOMRE";
export const AGENT = "AGENT";
export const CHANGE_HEADING = "CHANGE_MENU";
