import React from 'react';
import '../form.css';

function Input(props){
    
    return (
        <input type={props.inputType} 
                className={props.styles.join(' ')}
                id={props.inputId ? props.inputId : ""}
                name={props.inputName ? props.inputName : ""}
                placeholder={props.inputPlaceHolder ? props.inputPlaceHolder : ""}
                value={props.inputValue}
                onChange={props.onChangeHandler}
                onFocus={props.onFocusHandler}
                readOnly={props.readOnly}/>
    )
}

export default Input;