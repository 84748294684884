import React from 'react';
import '../form.css';

function InputFormGroup(props){

    return (
        <div className={props.styles.join(' ')} style={props.inlineStyle ? props.inlineStyle : null}>
            {props.children}            
        </div>
    );
}

export default InputFormGroup;