import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { menuToggleing, loggedoutHandler } from '../../actions/appActions';

import settings from '../../img/settings.png';
import logout from '../../img/logout.png';
import styles from './Header.module.css';

function Header (props) {
console.log(props);
    return (
        <header>
            <div className={styles.hamberg_menu} onClick={props.menuToggleing}>
                <span className = {props.menuToggle ? styles.hamberg_menu_first : ""}></span>
                <span className = {props.menuToggle ? styles.hamberg_menu_middle : ""}></span>
                <span className = {props.menuToggle ? styles.hamberg_menu_last : ""}></span>
            </div>
            <h1 className={styles.logo}>
                {props.config.logo}
            </h1>
            <nav className={styles.top_menu}>
                <ul>
                    <li>
                        <Link to="/agent/update-profile" style={{backgroundImage : `url(${settings})`, backgroundSize: "4rem"}}>
                            <h5>Setting</h5>
                        </Link>
                    </li>
                    <li>
                        <div style={{backgroundImage : `url(${logout})`}} onClick={props.loggedoutHandler}>
                            <h5>Logout</h5>
                        </div>
                    </li>
                </ul>
                <div className="clear"></div>
            </nav>
        </header>
    );    
}

const mapStateToProps = state => ({
    logo: state.app.logo,
    menuToggle: state.app.menuToggle,
    logedIn : state.app.logedIn,
    config : state.app.config
});

export default connect(mapStateToProps, {menuToggleing, loggedoutHandler})(Header);