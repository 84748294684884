import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styles from './loging.module.css';
import appStyle from '../../App.module.css';

import Form from '../from/Form';
import Inputfromgroup from '../from/formGroup/InputFormGroup';
import Label from '../from/formGroup/Label';
import Input from '../from/formGroup/Input';
import Formbutton from '../button/Formbutton';
import { loggedinHandler } from '../../actions/appActions';

class Login extends Component {

    state = {
        username : "",
        password : "",
        notification : {
            mzg : "",
            color : "red"
        }
    }

    onSubmitHandler = (event) => {
        event.preventDefault();
        const { username, password } = this.state;
        fetch('/api/auth/login', {
            method: 'POST',
            headers: {
              "Content-type": "application/json"
            },
            body: JSON.stringify({username, password})
          })
          .then(res => res.json())
          .then(res => {
              if(res.resp === "ok"){
                    this.setState({
                        ...this.state,
                        notification : {
                            mzg : "Successfully logged..!",
                            color : "green"
                        }
                    })
                    setTimeout(() => {
                        this.props.loggedinHandler(res);
                    }, 1000)
              }else{
                    this.setState({
                        ...this.state,
                        notification : {
                            mzg : res.resp,
                            color : "red"
                        }
                    })
              }            
        });
    }

    render(){
        return !this.props.logedIn ? (
            <div className={styles.container}>
                <div className={styles.loging_container}>
                    <h1>Agent Login</h1>
                    <hr />
                    <Form onSubmitHandler={this.onSubmitHandler} styles={["portal-form", "col-11"]}>
                        <Inputfromgroup styles={["form-group"]}>
                                <Label 
                                    labelFor={"user-name"}
                                    styles={["col-4"]}
                                    labelName={"Agent Username"} />
                                <Input
                                    inputType={"text"}
                                    styles={["col-7"]}
                                    inputId={"user-name"}
                                    inputName={"user-name"}
                                    inputValue={this.state.username}
                                    onChangeHandler={(e) => {
                                        this.state.notification.mzg !== "" ? this.setState({ ...this.state, username : e.target.value, notification : {mzg : "" , color : "red"} }) : this.setState({ username : e.target.value })
                                    }} />
                        </Inputfromgroup>
                        <Inputfromgroup styles={["form-group"]}>
                                <Label 
                                    labelFor={"password"}
                                    styles={["col-4"]}
                                    labelName={"Agent Password"} />
                                <Input
                                    inputType={"password"}
                                    styles={["col-7"]}
                                    inputId={"password"}
                                    inputName={"password"}
                                    inputValue={this.state.password}
                                    onChangeHandler={(e) => {
                                        this.state.notification.mzg !== "" ? this.setState({ ...this.state, password : e.target.value, notification : {mzg : "" , color : "red"} }) : this.setState({ password : e.target.value })
                                    }} />
                        </Inputfromgroup>
                        <Inputfromgroup styles={["form-group"]}>
                                <p className={appStyle.error_message} style={{ color : this.state.notification.color}}>{this.state.notification.mzg}</p>
                                <Formbutton
                                        type={"submit"}
                                        styles={["btn_sm btn_orange"]}>Submit</Formbutton>
                                <div className="clear"></div>
                        </Inputfromgroup>
                    </Form>
                    <p>{this.props.config.copyright}</p>
                </div>
            </div>
        ) : <Redirect to="/dashboard" />;
    }
}

const mapStateToProps = state => ({
    logedIn : state.app.logedIn,
    config : state.app.config
});

export default connect(mapStateToProps, { loggedinHandler } )(Login);


                        
                    
                    
                    