import React from 'react';
import './button.css';

function Formbutton(props){

    return (
        <button 
            type={props.type}
            style={props.inlineStyle}
            disabled={props.disabled}
            className={props.styles.join(' ')}>
                {props.children}
        </button>
    )
}

export default Formbutton;