import React from 'react';
import { NavLink } from 'react-router-dom';

import './navigationList.css';

function NavigationList (props){

    let menuItem = props.menu.map( (menuItem, i) => { 
            
        return (
            <NavLink 
                key={"nav-link" + i } 
                to={menuItem.uri} 
                exact 
                activeClassName="activeRoute"> 
                <li>{menuItem.menuName}</li> 
            </NavLink> );
    });
    return menuItem;
}

export default NavigationList;