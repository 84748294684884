import React from 'react';
import loadingGif from '../../img/loading.gif';

let Loading = () => {

    return (
        <div style={{
            width : "100%",
            height : "100vh",
            position : "relative"
        }}>
            <div style={{
                width : "auto",
                height : "auto",
                position: "absolute",
                top : "50%",
                left : "50%",
                transform : "translate(-50%, -50%)"
            }}>
                <img src={loadingGif} />
            </div>
        </div>
    )
}

export default Loading;