import * as actions from '../actions/types';

const initialState = {
    menuUri : []
}

export default (state = initialState, action) => {
    switch(action.type){
        case actions.MENU_URI:
            return {
                ...state,
                menuUri : action.payload
            };
        case actions.LOGGED_OUT:
            return initialState;
        default:
            return state;
    }
}