import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { menuUriHandler } from './actions/authActions';
import { checkLogin, setConfiguration } from './actions/appActions';

import Login from './component/login/Login';
import Home from './component/home/Home';
import Loading from './component/loading/Loading';
import Privateroute from './component/privateroute/Privateroute';

import styles from './App.module.css';

const AuthRouts = {};

AuthRouts.Dashboard = lazy(() => import('./component/dashbord/Dashboard'));
AuthRouts.Createcustomer = lazy(() => import('./component/authComponent/createCustomer/Createcustomer'));
AuthRouts.Createcustomerv2 = lazy(() => import('./component/authComponent/createCustomer/CreatecustomerV2'));
AuthRouts.Customerrequest   = lazy(() => import('./component/authComponent/createCustomerRequest/Customerrequest'));
AuthRouts.Createdemocustomer = lazy(() => import('./component/authComponent/createDemo/Createdemocustomer'));
AuthRouts.Customertransaction = lazy(() => import('./component/authComponent/transactions/Customertransaction/Customertransaction'));
AuthRouts.Viewcustomer = lazy(() => import('./component/authComponent/userdata/viewCustomer/Viewcustomer'));
AuthRouts.Createagent = lazy(() => import('./component/authComponent/createAgent/Createagent'));
AuthRouts.Createrollingagent = lazy(() => import('./component/authComponent/createAgent/Createrollingagent'));
AuthRouts.Agenttransaction = lazy(() => import('./component/authComponent/transactions/agentTransaction/Agenttransaction'));
AuthRouts.Viewagent = lazy(() => import('./component/authComponent/userdata/viewAgent/Viewagent'));
AuthRouts.Winlost = lazy(() => import('./component/authComponent/winLost/Winlost'));
AuthRouts.Agentupdate = lazy(() => import('./component/authComponent/agentUpdate/Agentupdate'));
AuthRouts.Customerhistory = lazy(() => import('./component/authComponent/history/Customerhistory'));
AuthRouts.Agenthistory = lazy(() => import('./component/authComponent/history/Agenthistory'));
AuthRouts.Points = lazy(() => import('./component/authComponent/points/Points'));
AuthRouts.Rolling = lazy(() => import('./component/authComponent/rolling/Rolling'));
AuthRouts.Rollingcommmanagement = lazy(() => import('./component/authComponent/rolling/Rollingcommmanagement'));
AuthRouts.Liverolling = lazy(() => import('./component/authComponent/rolling/liveRolling/LiveRolling'));
AuthRouts.Checkcusdetail = lazy(() => import('./component/authComponent/userdata/checkCusDetail/Checkcusdetail'));
AuthRouts.Allcusdetail = lazy(() => import('./component/authComponent/userdata/allCusDetail/Allcusdetail'));
AuthRouts.Registrationrequest = lazy(() => import('./component/authComponent/registrationRequest/Registrationrequest'));
AuthRouts.InactiveUsers = lazy(() => import('./component/authComponent/InactiveUsers/InactiveUsers'));

class App extends React.Component{

  state = {
    pRoute : null
  }

  getConfig = () => {
    fetch(`/api/auth/configuration/`)
            .then(res => res.json())
            .then(config => {
                this.props.setConfiguration({...config.resp});
            });
  }

  setRouting = () => {
    let pRoute = this.props.logedIn ? this.props.menu.map((menuItem, i) => {
      let Comp = AuthRouts[menuItem.menuValue];
      return (<Privateroute key={'route' + i} path={menuItem.uri} >
                  <Comp />
              </Privateroute>)
    }) : [];
    if(pRoute.length > 0)
      this.setState({
        pRoute : pRoute
      });
  }
  
  componentDidMount(){
    this.props.checkLogin();
    this.getConfig();
    this.setRouting();    
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.logedIn !== this.props.logedIn) this.props.checkLogin();
    if(this.state.pRoute === null) this.setRouting();
  }
  
  render(){
      let menuUri = this.props.logedIn ? this.props.menu.map(menuItem => menuItem.uri) : [];

      if(menuUri.length > 0) this.props.menuUriHandler(menuUri);

      return (      
        <Router>
          <Suspense fallback={<Loading />}>
            <Route path="/login" exact component={Login} />
            {this.props.logedIn ? 
            <div className={styles.home_area}>
                <Route path="/" component={Home} />
                <div className={`${styles.home_content} ${this.props.menuToggle ? styles.navigation_togle : ""}`}>
                    {this.state.pRoute}
                    <Route path="/agent/update-profile"  component={AuthRouts.Agentupdate}/>
                </div>
            </div> : <Redirect to="/login" />}
          </Suspense>        
        </Router>
    ); 
  }
  
}

const mapStateToProps = state => ({
  menuToggle : state.app.menuToggle,
  menu : state.app.menu,
  logedIn : state.app.logedIn,
  promoCode: state.app.promoCode
});

export default connect(mapStateToProps, { menuUriHandler, checkLogin, setConfiguration })(App);
