import React, {Component} from 'react';
import Aux from '../../hoc/Auxiliary';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { connect } from 'react-redux';
import { getPromoCode } from '../../actions/appActions';
import Button from "../button/Button";

import styles from './navigation.module.css';

import NavigationList from './NavigationList/NavigationList';

class Navigation  extends Component {

        strToUpper = (str) => {
            return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
        }

        shouldComponentUpdate(nextProps, nextState){
            console.log("NextProps", nextProps);
            console.log("ThisProps", this.props);

            return nextProps.menu.length !== this.props.menu.length || this.props.promoCode === "" ? true : false;
        }

        getPromoCode = () => {
            fetch('/api/auth/get-promo-code/')
            .then(res => res.json())
            .then(res => {
              this.props.getPromoCode(res.result);
            });
        }

        render(){
            if (this.props.promoCode === "") this.getPromoCode();           

            let props = this.props;
            let genurl = false;
            let getGenUrl = "";
            if(window.location.host === "agent.ballysbreeze.com" && props.promoCode != ''){
                genurl = true;
                getGenUrl = "https://ballysbreeze.com/?tryget=signup#" + props.promoCode;
            }
            let agentData = props.userData.result !== undefined ? props.userData.result : {};
            let view  = agentData.agent_name !== undefined && agentData.agent_name !== "" && props.menu.length > 0 ?
                            (<Aux>
                                <div className={styles.agent_detail}>
                                    <div>
                                        <img src={'https://storage.googleapis.com/itonecdn/uploads/default-image.png'} alt="avatar" /> 
                                    </div>                
                                    <h4>{this.strToUpper(agentData.agent_name)}</h4>
				                    {props.promoCode != '' ? (<h4>Promo Code: {props.promoCode}</h4>) : null}
                                    {window.location.host === "agent.ballysbreeze.com" && props.promoCode != ''}
                                    {genurl ? 
                                    <p style={{textAlign: "center"}}>
                                        <Popup trigger={<button
                                            className={["btn_sm btn_orange_light"]}
                                            style={{
                                                padding: "0.3rem 1%",
                                            }}
                                            
                                        >Gen Promo Url</button>}
                                                position="right center">
                                            <textarea  style={{
                                                fontSize: "1.3rem",
                                                fontFamily: "sans-serif"
                                            }}
                                            id="promo-code"
                                            value={getGenUrl}
                                            readOnly/>
                                            <button 
                                            className={["btn_sm btn_orange_light"]}
                                            style={{
                                                padding: "0.1rem 1%",
                                                display: "block",
                                                margin: "0 auto"
                                            }}
                                            onClick={(e) => {
                                                let copyText = document.getElementById('promo-code');
                                                copyText.select();
                                                copyText.setSelectionRange(0, 99999);    
                                                document.execCommand('copy');                                          
                                            }}
                                            >copy</button>
                                        </Popup>
                                    </p>
                                    : null}
                                    <h5>{new Date().toDateString()}</h5>
                                </div>
                                <ul>
                                    <NavigationList menu={this.props.menu} />
                                </ul>
                            </Aux>) : null;

            return view;
        }
        
}

const mapStateToProps = state => ({
    menu : state.app.menu,
    userData : state.app.userData,
    promoCode: state.app.promoCode
});

export default connect(mapStateToProps, { getPromoCode }) (Navigation);
