import React from 'react';
import './form.css';

function Form(props){

    return (
        <form onSubmit={props.onSubmitHandler} className={props.styles.join(' ')} style={{...props.inlineStyle}}>
            {props.children}
        </form>
    );
}

export default Form;