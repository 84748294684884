import * as actions from '../actions/types';

const initialState = {
    customers: {},
    agents : {},
    currency : {},
    heading : ""
}

export default (state = initialState, action) => {
    switch(action.type){
        case actions.CUSTOMRES :            
            return {
                ...state,
                customers : action.customers
            }
        case actions.CUSTOMRE :
            return {
                ...state,
                customers : {
                    ...state.customers,
                    [action.payload.user_id] : action.payload
                }
            }
        case actions.AGENTS : 
            return {
                ...state,
                agents : action.agents
            }
        case actions.AGENT :
            return {
                ...state,
                agents : {
                    ...state.agents,
                    [action.payload.id] : action.payload
                }
            }
        case actions.CURRENCY :
            return {
                ...state,
                currency : action.currency
            }
        case actions.CHANGE_HEADING :
            return {
                ...state,
                heading : action.heading
            }
        case actions.LOGGED_OUT:
            return initialState;
        default:
            return state;
    }
}