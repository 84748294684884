import React from 'react';
import '../form.css';

function Label(props){

    return (
        <label  htmlFor={props.labelFor} className={props.styles.join(' ')}>{props.labelName}</label> 
    )
}

export default Label;