import * as actions from './types';

export const menuToggleing = toggle => dispatch => {
    dispatch({
        type: actions.MENU_TOGGLE,
        payload: toggle
    })
}

export const loggedinHandler = payload => dispatch => {
    dispatch({
        type: actions.LOGGED_IN,
        ...payload
    })
}

export const loggedoutHandler = () => dispatch => {
    fetch('/api/auth/logout')
            .then(response => response.json())
            .then(res => {                
                dispatch({
                    type: actions.LOGGED_OUT,
                    bool : res.resp
                });
            });
}

export const checkLogin = () => dispatch => {
    fetch('/api/auth/check-login')
            .then(response => response.json())
            .then(res => {
                dispatch({
                    type: actions.CHECK_LOGIN,
                    bool : res.resp,
                    userData : res.agentData,
                    menu : res.menu
                });
            });    
}

export const subcribeAgentData = payload => dispatch => {
    dispatch({
        type: actions.SUBCRIBE_AGENT,
        payload
    });
}

export const setConfiguration = configData => dispatch => {
    dispatch({
        type: actions.CONFIG,
        payload: configData
    })
}

export const getPromoCode = payload => dispatch => {
    dispatch({
        type: actions.PROMO_CODE,
        payload: payload
    });
}
