import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Aux from '../../hoc/Auxiliary';
import Header from '../header/Header';
import Navigation from '../leftNavigation/Navigation';

import Appstyle from '../../App.module.css';
import navstyles from '../leftNavigation/navigation.module.css';

function Home (props){

    let checkRoute = props.match && props.match.path === "/" ? <Redirect to="/dashboard" /> : null;
    
    return (
        <Aux>
            <Header />            
            <nav className={`${navstyles.navigation} ${props.menuToggle ? Appstyle.navigation_togle : ""}`}>
                <Navigation />
            </nav>
            {checkRoute}
        </Aux>
    );    
}

const mapStateToProps = state => ({
    logedIn : state.app.logedIn,
    menuToggle : state.app.menuToggle
});

export default connect(mapStateToProps) (Home);