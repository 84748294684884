import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';

function Privateroute({ children, menu, dispatch, menuUri, ...rest }){
    return (
        <Route {...rest} render={({ location }) =>
            menuUri.find(uri => uri === rest.path) !== undefined ? ( children ) : (
                    <Redirect
                        to={{
                            pathname: "/dashboard",
                            state: { from: location }
                        }}
                    />
                )
            }
            />
    )
}

const mapStateToProps = state => ({
    menu : state.app.menu,
    menuUri : state.auth.menuUri
});

export default connect(mapStateToProps) (Privateroute);