import * as actions from '../actions/types';

const initialState = {
    config : {
        logo : "",
        copyright : ""
    },
    menuToggle : false,
    logedIn : false,
    userData : {},
    menu : [],
    promoCode: ""
}

export default (state = initialState, action) => {
    switch(action.type){
        case actions.MENU_TOGGLE:
            return {
                ...state,
                menuToggle : !state.menuToggle
            };
        case actions.LOGGED_IN:
            return {
                ...state,
                logedIn : !state.logedIn,
                userData : action.agentData,
                menu : action.menu
            }
        case actions.LOGGED_OUT:
            return {
                ...state,
                menuToggle : false,
                logedIn : false,
                userData : {},
                menu : [],
                promoCode: ""
            }
        case actions.CHECK_LOGIN:
            return {
                ...state,
                logedIn : action.bool,
                userData : action.userData,
                menu : action.menu
            }
        case actions.CONFIG:
            return {
                ...state,
                config : action.payload
            }
        case actions.SUBCRIBE_AGENT:
            return {
                ...state,
                userData : {
                    ...state.userData,
                    result : {
                        ...state.userData.result,
                        amount : action.payload
                    }
                }
            }
        case actions.PROMO_CODE:
            return {
                ...state,
                promoCode: action.payload
            }
        default:
            return state;
    }
}